import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/undraw_engineering_team_a7n2.svg"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Our Team</span>
                            <h2>Alone we can do so little; together we can do so much.</h2>  
                            <p>Our team is a diverse group of talented professionals who bring unique skills and perspectives to every project. By working together, we push boundaries, overcome challenges, and achieve remarkable results. Collaboration is at the heart of everything we do, and it's how we turn great ideas into reality.</p>
                            <Link to="../team" className="axil-btn btn-large btn-fill-primary">Our Team</Link>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;