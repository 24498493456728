import React from "react";
import FormOne from "../contact/FormOne";
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";

const AboutTwo = () => {
  return (
    <div className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="why-choose-us">
              <div className="section-heading heading-left">
                <span className="subtitle">About Us</span>
                <h3 className="title">Why branding matters?</h3>
                <p>
                  Branding creates a lasting impression and sets your business
                  apart. It builds trust, fosters customer loyalty, and ensures
                  a consistent identity across all touchpoints, helping your
                  business grow and succeed in competitive markets.
                </p>
              </div>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <FaCompress /> Strategy
                  </Accordion.Header>
                  <Accordion.Body>
                    Our strategic approach begins with a deep understanding of
                    your business objectives. We develop tailored,
                    results-driven plans that ensure you stand out in a
                    competitive market and achieve long-term success.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <FaCode /> Design
                  </Accordion.Header>
                  <Accordion.Body>
                    Our design philosophy is centered on creating impactful
                    visuals that resonate with your audience. We ensure every
                    element reflects your brand’s identity while delivering an
                    intuitive and engaging user experience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <FaGlobe /> Development
                  </Accordion.Header>
                  <Accordion.Body>
                    From seamless websites to robust applications, our
                    development process guarantees high-performance solutions
                    that are built to scale. We bring your vision to life with
                    clean code and cutting-edge technology.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box shadow-box mb--30">
              <h3 className="title">Get a free Keystroke quote now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
