import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>Tekmadev | {title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Maximize your online potential with Tekmadev - a reliable tech partner for businesses looking to enhance their online presence. From custom website and app creation to expert digital marketing and SEO, we've got you covered. Elevate your brand and reach new heights with Tekmadev." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;