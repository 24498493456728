import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';


const WhyChooseOne = () => {
    return (
      <div className="section-padding bg-color-light">
        <div className="container">
            <div className="row">
            <div className="col-lg-6">
                <div className="why-choose-us">
                <SectionTitle 
                    subtitle="About Us"
                    title="Why branding matters?"
                    description="Branding is essential because it shapes how your audience perceives your business. A strong brand builds trust, creates recognition, and sets you apart from competitors. It connects emotionally with customers, making your business memorable and influential."
                    textAlignment="heading-left"
                    textColor=""
                />
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FaCompress /> Strategy</Accordion.Header>
                            <Accordion.Body>
                                Our branding strategy begins with understanding your core values and market positioning. We create a tailored approach that ensures your brand stands out, resonates with your target audience, and communicates your unique message effectively.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><FaCode /> Design</Accordion.Header>
                            <Accordion.Body>
                                Design is where your brand comes to life. We focus on creating visually appealing, cohesive designs that reflect your brand’s personality and leave a lasting impression on your audience, making it both recognizable and memorable.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                            <Accordion.Body>
                                We ensure that your branding is applied consistently across all platforms and touchpoints. Whether it’s your website, social media, or marketing materials, we develop solutions that reinforce your brand and foster trust with your customers.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="why-choose-us mb--30">
                <div className="why-choose-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/about/toronto.webp"} alt="Office" />
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    )
}

export default WhyChooseOne;