import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Exceed Clients' and Colleagues' Expectations",
        para: "We strive to deliver exceptional results by going above and beyond for our clients and team members. Our commitment to excellence drives everything we do, ensuring we exceed expectations at every turn."
    },
    {
        id: 2,
        title: "Take Ownership and Challenge the Status Quo",
        para: "We encourage taking initiative and questioning the ordinary in a positive way. By fostering innovation and critical thinking, we continuously evolve and improve in all that we do."
    },
    {
        id: 3,
        title: "Be Brave, Curious, and Experiment – Learn from Successes and Failures",
        para: "We embrace a growth mindset, constantly exploring new ideas and learning from both victories and setbacks. Experimentation fuels progress, and every experience adds to our collective knowledge."
    },
    {
        id: 4,
        title: "Act with Integrity and Make Us Proud",
        para: "Our actions reflect who we are as a company. We are committed to upholding the highest standards of ethics and professionalism, ensuring that we represent our values proudly in every endeavor."
    },
    {
        id: 5,
        title: "Foster an Inclusive, Transparent, and Socially Responsible Culture",
        para: "We believe in building a work environment that values inclusivity, transparency, and social responsibility. Together, we create a culture that supports diversity and promotes positive change."
    }
]

const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Values"
                    title="Why Work with Us?"
                    description="At the core of our company are values that guide us in delivering exceptional work while fostering a culture of innovation, responsibility, and integrity. Here's why we believe we’re the right fit for you."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Bubble" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;