import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your message has been successfully sent.
    </Alert>
  );
};

const FormTwo = () => {

  const form = useRef();

  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    console.log('Sending email');
    
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Service ID from .env
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Template ID from .env
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Public Key from .env
      )
      .then(
        (result) => {
          console.log(result.text);
          showResult(true); // Show success message
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
  };

  // Hide the success message after 5 seconds
  useEffect(() => {
    if (result) {
      const timer = setTimeout(() => {
        showResult(false);
      }, 5000);
      return () => clearTimeout(timer); // Clear the timer when the component unmounts
    }
  }, [result]);

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="contact_name" // Updated name to use underscore
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact_email" // Updated name to use underscore
          required
        />
      </div>
      <div className="form-group">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="contact_phone" // Updated name to use underscore
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>How can we help you?</label>
        <textarea
          className="form-control"
          name="contact_message" // Updated name to use underscore
          rows="4"
          placeholder="A brief Description"
        ></textarea>
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Get Pricing Now
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormTwo;