import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormOne = () => {
  const form = useRef();
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Service ID from environment variables
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Template ID from environment variables
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Public Key from environment variables
      )
      .then(
        (result) => {
          console.log(result.text);
          showResult(true); // Show success message
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
  };

  // UseEffect to hide the result message after 5 seconds
  useEffect(() => {
    if (result) {
      const timer = setTimeout(() => {
        showResult(false);
      }, 5000);

      // Cleanup timer on component unmount or when result changes
      return () => clearTimeout(timer);
    }
  }, [result]);

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="contact_name" // Use underscores in name attribute
          placeholder="John Smith"
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact_email" // Use underscores in name attribute
          placeholder="example@mail.com"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="contact_phone" // Use underscores in name attribute
          placeholder="+123456789"
          required
        />
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Get Free Quote
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormOne;